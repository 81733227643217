/* ------------------------------------- blog section CSS Start  ---------------------------------*/

.singlePage-blogSection{
    padding-top: 150px;
    padding-bottom: 70px;
    .singlePage_container{
        .singlePage_main_content{
            background: var(--white-color);
            margin: -70px 20px 0;
            z-index: 1;
            position: relative;
            padding: 20px 30px;
            @include md{
                padding: 0;
                margin:40px 0 0;
            }
            .singlePage_main_content_title{
                margin-bottom: 30px;
                .singlePage_main_content_cat{
                    background: var(--primary-color);
                    padding: 5px 10px;
                    display: inline-block;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    border-radius: 5px;
                    color: var(--white-color);
                }
            }
            .singlePage_date_social{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                li{
                    display: inline-block; 
                    margin-left: 10px;
                }
            }
        }
        .singlePage_main_content_container{
             h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
                margin-bottom:15px;
                color:var(--dark-color);
                font-weight:800;
            }
            h2,.h2,h2 span,.h2 span {
                font-size:28px!important;
                margin-bottom:20px
            }
            h3,.h3,h3 span,.h3 span {
                font-size:20px!important
            }
            h4,.h4,h4 span,.h4 span {
                font-size:18px!important
            }
            h5,.h5,h5 span,.h5 span {
                font-size:16px!important
            }
            h6,.h6,h6 span,.h6 span {
                font-size:14px!important
            }
            ul {
                padding-left:35px;
                margin-bottom:35px
            }
            img {
                margin-bottom:25px;
                max-width:100%;
                height: auto;
            }
            p {
                margin-bottom:25px;
                word-break:break-word;
                overflow-wrap:break-word
            }
            .table-responsive {
                margin-bottom:25px
            }
            table {
                width:100%;
            }
            th,
             td {
              padding:8px 10px
            }
            table tr:first-child th {
                border-bottom:4px solid #0a66c2
            }
            .is-type-video {
                margin: 16px 40px;
            }
            .w-video-h{
                iframe{
                    width: 100%;
                    height: 350px;
                    @include md{
                        height: 150px;
                    }
                } 
            }
           
           
            .infusionForm input, .infusionForm textarea, .infusion-form input, .infusion-form textarea {
                font-weight: 300;
                background: var(--white-color)!important;
                border: 1px solid #bfbfbf !important;
                margin: 0;
                padding: 6px;
                display: inline-block;
                line-height: 1.2 !important;
                border-radius: 0;
                width: 100%;
                margin-top: 10px;
            }
           
            .infusion-submit {
                button{
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: 1px;
                    display: inline-block;
                    padding: 12px 35px;
                    min-width: 165px;
                    border-radius: 3px;
                    text-transform: capitalize;
                    text-align: center;
                    z-index: 1;
                    overflow: hidden;
                    position: relative;
                    transition: all 0.5s;
                    color: var(--light-color);
                    border: 1px solid var(--primary-color);
                    background-color: var(--primary-color)!important;
                    position: relative;
                }
            }
            
        }
       .wp-block-button{
        a{
            
                font-size: 16px;
                font-weight: 800;
                letter-spacing: 1px;
                display: inline-block;
                padding: 12px 35px;
                min-width: 165px;
                border-radius: 3px;
                text-transform: capitalize;
                text-align: center;
                z-index: 1;
                overflow: hidden;
                position: relative;
                @include transition;
                @include md {
                    min-width: 125px;
                    font-size: 14px;
                }
                color: var(--light-color);
                border: 1px solid var(--primary-color);
                background-color: var(--primary-color)!important;
                position: relative;
                &:hover,
                &:focus,
                &:active {
                    color: var(--light-color);
                    border: 1px solid var(--secondary-color)!important;
                    background-color: var(--secondary-color)!important;
                    transform: translateY(-10px);
                    cursor: pointer;
                }
        }
       }
    }
    .blogsingle_sidebar{
        position: sticky;
        top: 100px;
        @include md{
            position: relative;
            margin-top: 40px;
            top: unset;
        }
        .blogSidebar-box {
            margin-bottom: 40px;
            border: 1px solid #eee;
            padding: 40px;
            border-radius: 3px;
            @include md{
                padding: 20px;
            }
            .sidebar_title{
                text-transform: capitalize;
                font-size: 22px;
                font-weight: 600;
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 25px;
                color: var(--dark-color);
                &::after {
                    content: "";
                    display: block;
                    left: 0px;
                    bottom: 0px;
                    position: absolute;
                    background-color: var(--primary-color);
                    width: 60px;
                    height: 2px;
                }
            }
            &.blogSidebar-signup-form{
                form{
                    input {
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    height: 50px;
                    margin-bottom: 10px;
                    font-size: 14px;
                    width: 100%;
                    padding: 5px;
                    padding-left: 15px;
                }
                }
            }
            &.blogSidebar-tags{
                li{
                    display: inline-block;
                    margin:5px auto;
                    margin-right: auto;
                    margin-right:5px;
                    a{
                        display: inline-block;
                        color: var(--dark-color);
                        padding:5px 10px;
                        font-size: 13px;
                        background: #f9f9f8;
                        border: 1px solid var(--primary-color);
                        border-radius: 30px;
                    }
                }
            }
        }
    }
    .recentPost_grid{
        background: var(--white-color);
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
        margin-top: 20px;
        h3{
            font-size: 24px;
            margin-bottom: 15px;
            @include md{
                font-size: 20px;
            }
            a{
                color: var(--dark-color);
            }
        }
        .recentPost_grid_content{
            padding: 30px;
            p{
                margin-bottom: 0;
            }
            .blogMeta_box{
                font-size: 13px;
                margin-bottom: 10px;
            }
        }
    }
}

/* ------------------------------------- blog section CSS Ends  ---------------------------------*/

