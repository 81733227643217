/* ------------------------------------- More Lead CSS Start  ---------------------------------*/

.moreLeads_section{
   padding: 70px 0;
   overflow: hidden;
   position: relative;
   .moreLeads_img{
    @include md{
        margin-bottom:20px;
    }
   }
}


/* ------------------------------------- More leads  CSS End  ---------------------------------*/


/* ------------------------------------- Lead Tabs  CSS Start  ---------------------------------*/
.leadTabs{
    background: #f2f6f9;
    padding: 70px 0;
    overflow: hidden;
    position: relative;
    .nav.nav-pills {
        position: relative;
        z-index: 5;
        border: 0;
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        background-color: var(--light-color);
        box-shadow: 0 22px 40px 0 rgba(0, 0, 0, 0.05);
        padding: 0 10px;
        margin-bottom: 30px;
        @include md{
            text-align: center;
            justify-content: center;
        }
        li {
            a {
                position: relative;
                border: none;
                padding:15px 15px;
                min-width: 200px;
                color: var(--dark-color);
                font-size: 15px;
                letter-spacing: .2em;
                text-transform: capitalize;
                border-radius: 0;
                z-index: 1;
                display: block;
                font-weight:800;
                width: 100%;
                text-align: center;
            
                @media (max-width: 767px) {
                    padding: 10px;
                    min-width: unset;
                    font-size: 13px;
                }
                &::after  {
                    position: absolute;
                    content: '';
                    bottom: -2px;
                    left: 0;
                    width: 0%;
                    height: 4px;
                    background-color:var(--primary-color);
                    z-index: 1;
                    transition: .3s ease-in-out;
                }
              
                &.active {
                    background: var(--light-color);
                    &::after {
                        width: 100%;
                        transition: .3s ease-in-out;
                    }
                }
              
            }
        }
    }
    .leadTabs_content{
        h2{
            font-size: 28px;
        }
    }
    .theme-btn{
        min-width: 300px;
        @include md{
            min-width: unset;
        }
    }
}
/* ------------------------------------- Lead tabs CSS Start  ---------------------------------*/

/* ------------------------------------- Latest Feature design CSS Start  ---------------------------------*/
.latestFeature_design{
    position: relative;
    overflow: hidden;
    padding: 70px 0;
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 231px;
        height: 100%;
        top: 0;
        background: url('../../images/footer-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after{
        content:'';
        background-image: url(../../images/dots.png);
        width: 85px;
        height: 62px;
        left: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;
    }
    .latestFeature_design_content{
        @include md{
            margin-top: 20px;
        }
    }
}
/* ------------------------------------- Latest Feature design CSS ends  ---------------------------------*/

/* ------------------------------------- Built to agent  CSS Start  ---------------------------------*/
.builtFor_agent{
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    background: #f2f6f9;
    @include md{
        padding: 50px 0;
    }
    .col-md-6{
        .builtFor_agent_grid{
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
            padding: 30px;
            background: var(--light-color);
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
            &::before{
                content:'';
                background-image: url(../../images/why-bg.png);
                width: 135px;
                height: 60px;
                right: 0;
                position: absolute;
                bottom: 0;
                z-index: -1;
            }
            .builtFor_agent_grid_title{
                margin-bottom: 20px;
                h3{
                    font-size: 24px;
                    margin-top: 20px;
                    @include md{
                        font-size: 20px;
                    }
                }
            }
            .builtFor_agent_grid_icon{
                background-color: #f2f6f9;
                height: 75px;
                width: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
            p{
                margin-bottom: 0;
            }
        }
        &:nth-child(2),  &:nth-child(3){
            .builtFor_agent_grid{
                &::before{
                    background-image: url(../../images/why-img-2.png);
                    height:68px;
                    width: 150px;
                }
            }
        }
        &:nth-child(4){
            .builtFor_agent_grid{
                &::before{
                    background-image: url(../../images/why-img-3.png);
                    height:58px;
                    width: 139px;
                }
            }
        }
    }
   
}
/* ------------------------------------- Built to agent  CSS ends  ---------------------------------*/

/* ------------------------------------- Latest Update  CSS Start  ---------------------------------*/

.latestUpdate{
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    background:#091c27;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 231px;
        height: 100%;
        top: 0;
        background: url('../../images/footer-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after{
        content:'';
        background-image: url(../../images/side-design.png);
        width:134px;
        height:251px;
        left: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;
        background-repeat: no-repeat;
    }
    .latestUpdate_img{
        margin-top: 20px;
    }
}

/* ------------------------------------- Latest Update  CSS ends  ---------------------------------*/

/* ------------------------------------- property expert css starts  ---------------------------------*/
.propertyExpert{
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    background: #f2f6f9;
    .propertyExpert_grid{
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
        padding: 30px;
        background: var(--light-color);
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
        text-align: center;
       .propertyExpert_grid_content{
        margin-top: 20px;
       }
       .propertyExpert_grid_img {
            position: relative;
            height: 270px;
            overflow: hidden;
            border-radius: 10px;
            img{
                object-fit: cover;
                width: 100%;
            }
        }
        .propertyExpert_btn{
            text-align: center;
            background: transparent;
            margin:  20px auto;
            position: relative;
            z-index: 1;
            &::before {
                content: '';
                position: absolute;
                left: 24px;
                top: 20px;
                width: 30px;
                height: 30px;
                z-index: -1;
                display: block !important;
                border-radius: 50%;
                background: #999;
                -webkit-animation: dot-zoom 3s infinite;
                animation: dot-zoom 3s infinite;
                  animation-delay: 0s;
                animation-delay: 0s;
                -webkit-animation-delay: 1.5s;
                animation-delay: 1.5s;
                will-change: transform;
              }
              @keyframes dot-zoom {
                0% {
                  opacity: 1;
                  -webkit-transform: translate(-50%, -50%) scale(0.5);
                  transform: translate(-50%, -50%) scale(0.5);
                }
                100% {
                  opacity: 0;
                  -webkit-transform: translate(-50%, -50%) scale(4);
                  transform: translate(-50%, -50%) scale(4);
                }
                0% {
                  opacity: 1;
                  -webkit-transform: translate(-50%, -50%) scale(0.5);
                  transform: translate(-50%, -50%) scale(0.5);
                }
                100% {
                  opacity: 0;
                  -webkit-transform: translate(-50%, -50%) scale(4);
                  transform: translate(-50%, -50%) scale(4);
                }
            }
          
        }
        p{
            margin-bottom: 0;
        }
    }
}

.expertVideo{
    .modal-dialog {
        max-width: 700px;
        iframe {
            width: 100%;
            height: 400px;
        }
        .btn-close {
            position: absolute;
            right: 0;
            background-color: #dde2ea;
            z-index: 1;
            opacity: 1;
            height: 35px;
            width: 35px;
            border-radius: 50%;
        }
    }
}
/* ------------------------------------- property expert css ends  ---------------------------------*/


/* ------------------------------------- client review css starts  ---------------------------------*/
.clientReview{
    padding: 70px 0;
    .clientReview_grid{
        margin: 20px 0;
        .clientReview_grid_star{
            margin-bottom: 30px;
        }
        padding: 30px;
        background: #f2f6f9;
        text-align: center;
        h2{
            font-size:18px;
        }
        P{
            min-height: 77px;
        }
        &.active{
            background: #091c27;
            h2{
                color: var(--light-color);
            }
            p{
                color: var(--light-color);
                
            }
        }
    }
}

/* ------------------------------------- client review css ends  ---------------------------------*/