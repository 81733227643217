
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
    font-family: "Sarabun", serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: var(--text-color);
    overflow-x: hidden;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    --text-opacity: 1;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -ms-touch-action: auto;
    touch-action: auto;
    position: relative;
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Sarabun", serif;
    font-weight: 800;
    padding: 0;
    margin: 0;
    color: var(--dark-color);
}