/* ------------------------------------- Home Banner CSS Start  ---------------------------------*/

.home-banner{
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 150px 0 80px;
    background-size: cover;
    background-position: center center;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: block!important;
        background: linear-gradient(90deg, #06283D 0%, #00286552 100%);
    }
    @include md{
        padding: 100px 0 60px;
    }
  
    h1{
        font-size:72px;
        color: var(--white-color);
        @include md{
            font-size: 28px;
        }
        @media only screen and (min-width: 768px) and (max-width:991px) {
            font-size:40px;
        }
    }
    p{
        color: #ffffffb3;
        font-size: 18px;
    }
    .home-banner-form{
        background: #091c27;
        max-width: 500px;
        margin: 0 auto;
        padding: 30px;
        @include md{
            margin-top: 30px;
        }
        h2{
            color: var(--white-color);
            margin-bottom: 5px;
        }
        p{
            color: var(--secondary-color);
            margin-bottom: 20px;
        }
        input[type="text"],input[type="email"],textarea{
            padding: 16px 24px 16px 24px;
            border: 1px solid #7a8c94;
            &::placeholder{
                color: #7a8c94;
            }
        }
        .theme-btn{
            font-size: 20px;
            &:hover{
                transform: unset;
            }
        }
    }
}


/* ------------------------------------- Home Banner CSS End  ---------------------------------*/

/* ------------------------------------- why choose us section starts ---------------------------------*/
.whyChoose_us{
    padding: 100px 0;
    position: relative;
    overflow: hidden;
    @include md{
        padding: 50px 0;
    }
    .col-md-6{
        .whyChoose_us_grid{
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
            padding: 30px;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
            &::before{
                content:'';
                background-image: url(../../images/why-bg.png);
                width: 135px;
                height: 60px;
                right: 0;
                position: absolute;
                bottom: 0;
                z-index: -1;
            }
            .whyChoose_us_grid_title{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                h3{
                    font-size: 24px;
                    @include md{
                        font-size: 20px;
                    }
                }
            }
            .whyChoose_us_grid_icon{
                background-color: #f2f6f9;
                height: 75px;
                width: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
            p{
                margin-bottom: 0;
                min-height: 80px;
            }
        }
        &:nth-child(2),  &:nth-child(3){
            .whyChoose_us_grid{
                &::before{
                    background-image: url(../../images/why-img-2.png);
                    height:68px;
                    width: 150px;
                }
            }
        }
        &:nth-child(4){
            .whyChoose_us_grid{
                &::before{
                    background-image: url(../../images/why-img-3.png);
                    height:58px;
                    width: 139px;
                }
            }
        }
    }
   
}
/* ------------------------------------- why choose us section ends ---------------------------------*/

/* ------------------------------------- home client section starts ---------------------------------*/
.homeClient{
    background: #091c27;
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 231px;
        height: 100%;
        top: 0;
        background: url('../../images/footer-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after{
        content:'';
        background-image: url(../../images/dots.png);
        width: 85px;
        height: 62px;
        left: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;
    }
}
/* ------------------------------------- home client section ends ---------------------------------*/



/* ------------------------------------- Lead site tools section starts ---------------------------------*/

.leadSite_tools{
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    
    .leadSite_tools_grid{
        position: relative;
        padding: 20px;
        background: var(--white-color);
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
        border-left: 4px solid var(--secondary-color);
        margin-bottom:20px;
        h3{
            font-size: 20px;
            font-weight: 800;
            margin-bottom: 15px;
            @include md{
                font-size: 20px;
            }
        }
        p{
            margin-bottom: 0;
        }
        &.ww_grid2{
            border-left: 4px solid #e7b317;
        }
        &.ww_grid3{
            border-left: 4px solid var(--primary-color);
        }
    }
    .leadSite_tools_cta .theme-btn{
        display: inline-block;    
    }   
    .leadSite_tools_img{
        @include md{
            margin-top: 20px;
        }
    }
    .white-btn{
        margin-left: 15px;
        @media(max-width:400px) {
            margin-left: 0;
        }
    }
}

/* ------------------------------------- Lead site tools section ends ---------------------------------*/

/* ------------------------------------- Leads video section starts ---------------------------------*/

.leadsVideo{
    background: #091c27;
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 231px;
        height: 100%;
        background-image: url('../../images/footer-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after{
        content:'';
        background-image: url(../../images/side-design.png);
        width:134px;
        height:251px;
        left: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;
        background-repeat: no-repeat;
    }
    .leadsVideo_in{
        @include md{
            margin-bottom: 20px;
        }
        iframe{
            @include md{
                height: 220px;
            }
        }
    }
}

/* ------------------------------------- Leads video section ends ---------------------------------*/

/* ------------------------------------- Expert Review Start ---------------------------------*/

.expertReviews{
    padding: 70px 0;
    background: #f2f6f9;
    position: relative;
    overflow: hidden;
    .carousel{
        .carousel-inner {
            display: flex;
            overflow-x: hidden;
            padding: 1em;
            @media screen and (min-width: 576px) {
                display: flex;
                width: 90%;
                margin-inline: auto;
                padding:0;
                overflow: hidden;
                
            }
        }
        .carousel-item {
            flex: 0 0 auto; /* Ensure items do not shrink */
            @media screen and (min-width: 767px) {
                display: block;
                margin-right: 0;
                flex: 0 0 calc(100% / 2);
            }
            @media screen and (min-width: 1200px) {
                display: block;
                margin-right: 0;
                flex: 0 0 calc(100% / 3);
            }
        }
        img {
            width: 80px;
            border-radius: 5px;
            height: auto;
            margin-right: 1rem;
            overflow: hidden;
        }
        .card {
            margin: 0 0.5em;
            border: 0;
            background: var(--white-color);
            text-align: center;
            border-radius: 0;
            h3{
                font-size: 16px;
                margin-bottom: 0;
            }
            .text-secondary{
                font-size: 14px;
                line-height: 1;
            }
            .card-text{
                min-height: 130px;
            }
            .expertReviews_img{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
            }
        }
        .carousel-control-prev,
        .carousel-control-next {
          width: 3rem;
          height: 3rem;
          background-color: grey;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
      }

      .carousel-control-prev {
        @media screen and (min-width: 992px) {
            left: -50px;
        }
    }

    .carousel-control-next {
        @media screen and (min-width: 992px) {
            right: -50px;
        }
    }

    }
}

/* ------------------------------------- Expert Review Ends ---------------------------------*/

/* ------------------------------------- Who We are Start ---------------------------------*/

.whoWe_are{
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    .whoWe_are_grid{
        background: var(--white-color);
        padding: 30px;
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
        text-align: center;
        @include lg{
            margin-top:20px;
        }
        h3{
            font-size: 20px;
            margin-bottom: 15px;
            margin-top: 20px;
        }
    }
    .whoWe_are_grid_icon{
        background-color: #f2f6f9;
        height: 75px;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:  0 auto;
    }
    margin-right: 10px;
    .whoWe_are_img{
        margin-bottom: 30px;
    }
}

/* ------------------------------------- Who Weare Ends ---------------------------------*/

/* ------------------------------------- Home Blog Section starts ---------------------------------*/

.homeBlog{
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    .Blog_grid_img{
        height: 245px;
        position: relative;
        display: block;
        overflow: hidden;
        img{
            height: 245px;
            object-fit: cover;
        }
    }
}



/* ------------------------------------- Home Blog Section ends ---------------------------------*/

/* ------------------------------------- Grow Site Section starts ---------------------------------*/
.growSite_cta {
    margin-bottom: 70px;
    position: relative;
    .growSite_cta_content {
        border-radius: 15px;
        background: linear-gradient(90deg, rgb(6, 30, 52) 0%, rgb(13, 111, 173) 90%);
        padding: 50px;
        position: relative;
        @include md{
            padding: 50px 20px;
        }
    }
    .main-heading {
        max-width: 800px;
      }
}
  
//   .autoPilot_performance_content::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 240px;
//     height: 240px;
//     background-image: url('https://aresstorageaccount.blob.core.windows.net/applicationcontainer/banner/1460a1db-5ef0-4863-9d02-6ff752a856b0.png');
//     background-size: contain;
//     background-repeat: no-repeat;
//     opacity: 0.3;
//   }
//   .autoPilot_performance_content::after {
//     content: '';
//     position: absolute;
//     right: 0px;
//     bottom: 0;
//     width: 593px;
//     height: 483px;
//     opacity: 0.1;
//     display: block;
//     background-image: url('https://aresstorageaccount.blob.core.windows.net/applicationcontainer/banner/ba11ceee-33d9-4c85-8e9b-d2103528b237.png');
//     background-position: center;
//     background-size: contain;
//     background-repeat: no-repeat;
//   }

/* ------------------------------------- Grow Site Section Ends ---------------------------------*/