// Mobile View Responsiveness
$screen-sm-min: 575px !default;
$screen-md-min: 767px !default;
$screen-lg-min: 991px !default;
$screen-xl-min: 1200px !default;
//Color Combination
$whiteColor :#fff;
$lightColor :#fff;
$darkColor :#091c27;
$textColor :#7a8c94;
$primaryColor: #1C6EEE;
$secondaryColor: #47B5FF;
$ternaryColor:  #0c387c;
html {
    --primary-color: #{$primaryColor};
    --secondary-color: #{$secondaryColor};
    --ternary-color: #{$ternaryColor};
    --dark-color: #{$darkColor};
    --text-color: #{$textColor};
    --light-color: #{$lightColor};
    --white-color: #{$whiteColor};
}
