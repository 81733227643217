// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

// Custom devices
@mixin rwd($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}

@mixin transition {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}