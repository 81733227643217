.site-footer {
    position: relative;
    background-color: var(--ternary-color);
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 231px;
        height: 100%;
        top: 0;
        background: url('../../images/footer-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
    }
    .footer-text{
        color: var(--white-color);
        margin: 20px 0 12px;
        font-size: 15px;
    }
    .copyright-text {
        
        .copyright-text-container{
            border-top: 1px solid #1b4484;
            display: flex;
            align-items: center;
            @include md{
                flex-direction: column;
                align-items: flex-start;
            }
            .footer-logo{
                @include md{
                    margin-top: 20px;
                }
            }
        }
        .copyright-text-content{
            border-left: 1px solid #1b4484;
            padding: 40px 0px 40px 20px;
            margin-left: 20px;
            @include md{
                border-left: 0;
                padding: 15px 0;
                margin-left: 0;
            }
            p {
                color: var(--light-color);
                font-size: 15px;
                line-height: 1.25;
                margin-bottom: 0;
                a{
                    color: var(--light-color);
                }
            }
        }
       
    }
    .pre-footer{
        padding: 50px 0;
    }
    .footer-menu-container{
        .footer-menu-title{
            color: var(--white-color);
            font-weight: 800;
            font-size: 20px;
            margin-bottom: 15px;
        }
        .footer-menu-contact{
            margin-bottom: 15px;
            .footer-menu-contact-title{
                font-size: 12px;
                font-weight: 800;
                color: var(--light-color);
            }
            p,a{
                color:#ffffffb3;
            }
        }
        ul{
            li{
                margin-bottom: 13px;
                a {
                    line-height: 20px;
                    position: relative;
                    color:#ffffffb3;
                    display: block;
                    margin-top: 8px;
                    text-transform: capitalize;
                }
               
            }
        }
    }
}
