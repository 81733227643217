.sitemap-section {
  background: #fff;
  padding: 30px;

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
  }

  hr {
    border: none;
    height: 2px;
    background: #0073e6;
    width: 80px;
    margin-bottom: 20px;
  }

  .ul-Style {
    // list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      font-size: 1.3rem;
      

      a {
        text-decoration: none;
        font-weight: 600;
        transition: color 0.3s ease;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.sitemap-category {
  margin-top: 20px;
  padding-left: 15px;

  h3 {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 10px;
  }
}

.sitemap-sublist {
  list-style-type: circle;
  padding-left: 25px;

  li {
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .page-banner-section {
    padding: 40px 0;

    h1 {
      font-size: 2rem;
    }
  }

  .sitemap-section {
    padding: 20px;

    h2 {
      font-size: 1.8rem;
    }
  }
}