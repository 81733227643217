/*---------------------------- Header CSS Start ------------------------- */

.site-header {
    background: transparent;
    position: absolute;
    z-index: 11;
    width: 100%;
    top: 0;
    border-bottom: 1px solid #ffffff1a;
    padding: 6px 0;
    @include transition;
    @include lg{
        padding: 0;
        background: var(--primary-color);
    }
    &.header-active{
        position: sticky;
        top: 0;
        background: var(--primary-color);
    }
    @media (max-width: 991px) {
        .container-mid {
            padding: 0;
        }
        padding: 10px 0;
    }
    &.site-header-bg{
        background: var(--primary-color);
    }
    .navbar-brand {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        img {
            width:230px;
            flex-shrink: 0;
            @media (max-width: 991px) {
                max-width: 150px;
            }
        }
    }
    .navbar {
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: 0;
        @media (max-width: 991px) {
            padding: 0 15px;
        }
        .headerCta{
            a{
                border: 1px solid var(--light-color);
                color: var(--light-color); 
                padding:12px 40px;
                font-weight: 800;
                line-height: 1.2;
                border-radius: 5px;
                display: inline-block;
                position: relative;
                transition: .3s ease-in-out;
                span{
                    display: inline-block;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        top:calc(50% + 1px);
                        width: 8px;
                        height: 8px;
                        left: -18px;
                        transform: translateY(-50%) rotate(45deg);
                        border-top: 2px solid var(--light-color);
                        border-right: 2px solid var(--light-color);
                    }
                }
                &:hover{
                        transform: translateY(-5px);
                        transition: .3s ease-in-out;
                }
                @include lg{
                    border-color: var(--dark-color);
                    color: var(--dark-color);
                    margin-top: 10px;
                    span{
                        &::before{
                            border-color: var(--dark-color);
                        }
                    }
                }
            }
        }
        .navbar-nav {
            @media (max-width: 991px) {
                margin: 0;
            }
           
            li {
                display: inline-block;
                &.dropdown:nth-last-child(2),
                &.dropdown:last-child {
                    .dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
                &.dropdown.show {
                    a::after {
                        transform: rotate(-180deg);
                    }
                }
                @media (max-width: 767px) {
                    display: block;
                }
                a {
                    text-transform: capitalize;
                    padding: 15px 15px;
                    font-size: 16px;
                    line-height: 35px;
                    color: var(--light-color);
                    display: block;
                    text-align: center;
                    @include transition;
                    font-weight: 600;
                    cursor: pointer;
                    position: relative;
                    z-index: 1;
                    &:hover,
                    &:active,
                    &:focus {
                        outline: 0;
                        color: var(--secondary-color);
                        @include transition;
                    }
                    @media (max-width: 991px) {
                        color: var(--dark-color);
                        font-size: 16px;
                        line-height: 26px !important;
                        border-top: 1px solid #eee;
                        &:hover,
                        &:active,
                        &:focus {
                            outline: 0 !important;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }
                    }
                    @media only screen and (min-width: 992px) and (max-width:1200px) {
                        padding: 10px 8px;
                        font-size: 14px;
                    }
                }
                @media (max-width: 991px) {
                    &:last-child a {
                        border-bottom: 1px solid #eee;
                    }
                }
                &.dropdown {
                    &:hover {
                        .dropdown-menu {
                            @media (min-width: 992px) {
                                display: block;
                            }
                        }
                    }
                }
                .dropdown-menu {
                    border: none;
                    border-top: none;
                    border-bottom: none;
                    top: 55px;
                    background-color: var(--light-color);
                    border-radius: 0;
                    padding: 0;
                    min-width: 13rem;
                    box-shadow: none;
                    margin-top: 0px;
                    @include lg {
                        margin: 0;
                    }
                    @media (min-width:992px) and (max-width: 1199px) {
                        left: auto;
                        right: 0;
                    }
                }
                ul {
                    li {
                        display: block;
                        a {
                            padding: 15px;
                            font-size: 14px;
                            line-height: 1;
                            background: var(--primary-color);
                            color: var(--light-color);
                            border-bottom: 1px dotted var(--light-color);
                            display: block;
                            font-weight: 600;
                            text-align: left;
                            @include transition;
                            &:hover {
                                color: var(--seconadry-color);
                                background: var(--light-color);
                                @include transition;
                            }
                            @media (max-width: 992px) {
                                text-align: center;
                            }
                        }
                        &:last-child a {
                            border-bottom: 0px dotted var(--light-color);
                        }
                    }
                }
               
            }
           
        }
        .navbar-toggler {
            background: transparent;
            border-radius: 0;
            outline: none;
            padding: .25rem;

            &:focus{
                box-shadow: none;
            }

            .icon-bar {
                width: 22px;
                height: 2px;
                background-color: var(--white-color);
                display: block;
                @include transition;
                margin: 4px 0;
            }
            .top-bar {
                -webkit-transform: rotate(-42deg);
                transform: rotate(-42deg);
                -webkit-transform-origin: 90% 90%;
                transform-origin: 90% 90%;
            }
            .middle-bar {
                opacity: 0;
            }
            .bottom-bar {
                -webkit-transform: rotate(42deg);
                transform: rotate(42deg);
                -webkit-transform-origin: 90% 10%;
                transform-origin: 90% 10%;
            }
            &.collapsed {
                .top-bar {
                    transform: rotate(0);
                }
                .middle-bar {
                    opacity: 1;
                }
                .bottom-bar {
                    transform: rotate(0);
                }
            }
        }
    }
   
    .navbar-collapse {
        position: relative;
        @media (max-width: 991px) {
            position: absolute;
            top: 42px;
            left: -100%;
            padding: 0;
            width: 100%;
            text-align: center;
            overflow-y: scroll;
            height: calc(100vh - 42px);
            display: block;
            @include transition;
            overflow-y: scroll;
            &.collapsing {
                height: calc(100vh - 42px);
                margin-right: 50%;
                display: block;
                @include transition;
            }
            &.show {
                left: 0;
                background: var(--light-color);
            }
        }
    }
}


/*---------------------------- Header CSS End ------------------------- */

