/* ------------------------------------- Eap Pricing CSS Start  ---------------------------------*/

.eapPricing{
    padding: 70px 0;
    position: relative;
    .container-mid{
        max-width: 1400px;
    }
    .main-heading{
        .form-switch{
            display: flex;
            justify-content: center;
            margin-top: 30px;
            padding-left: 0;
            width: 250px;
             margin: 0 auto;
            .form-check-input{
                float: unset;
                margin:0;
                width: 50px;
                height: 25px;
                background-color: var(--primary-color);
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
                &:focus{
                    box-shadow: unset;
                    outline: none;
                }
            }
            label{
                font-size: 20px;
                color: var(--dark-color);
                font-weight: 800;
            }
         .formSwitch {
                width: 80px;
                margin-top: 7px;
            }
        }
    }
    .eapPricing_grid{
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.07);
        background: var(--white-color);
        display: flex;
        flex-direction: column;
        height: 100%;
        .eapPricing_grid_content{
            padding: 20px;
        }
        h3{
            font-size: 24px;
            text-align: center;
            min-height: 58px;
            @include md{
                font-size: 20px;
                min-height:  auto;
            }
        }
        .sub-title{
            font-size: 16px;
            line-height: 1.3;
            margin-top: 10px;
            text-align: center;
        }
        .pack-name{
            color: var(--primary-color);
            font-weight: 800;
            font-size: 50px;
            line-height: 1;
            margin-top: 15px;
            text-align: center;
            @include md{
                font-size: 35px;
            }
        }
        .packPrice{
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .main-price{
                font-size: 40px;
                color: var(--dark-color);
                font-weight: 800;
                display: inline-block;
                margin-right: 5px;
            }
            .price-time{
                text-transform: capitalize;
            }
        }
        .eapPricing_grid_feature{
            border-top: 1px solid #80919933;
            padding:10px 20px;
            .eapPricing_grid_feature_title{
                color: var(--dark-color);
                font-weight: 800;
                margin-bottom: 10px;
            }
            .ulStyle{
                margin-bottom: 0;
                li{
                    padding-left: 30px;
                    margin-bottom:5px;
                    &::before{
                        background-image: url(../../images/rightcheck.webp);
                        top: 0px;
                    }
                }
            }
        }
        &.active{
            background: #091c27;
            h3{
                color: var(--white-color);
            }
            .packPrice{
                .main-price{
                    color: var(--white-color);
                }
            }
        }
    }
}

/* ------------------------------------- Eap Pricing Ends ---------------------------------*/

/* -------------------------------------   Eap agent videos section starts ---------------------------------*/
.agentVideos{
    background: #091c27;
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 231px;
        height: 100%;
        background-image: url('../../images/footer-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after{
        content:'';
        background-image: url('../../images/side-design.png');
        width:134px;
        height:251px;
        left: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;
        background-repeat: no-repeat;
    }
    .agentVideos_gird{
        margin-bottom: 20px;
        iframe{
            width: 100%;
        }
    }
    h3{
        color: var(--white-color);
        margin-bottom: 20px;
        @include md{
            font-size: 20px;
        }
    }
}
/* -------------------------------------   Eap agent videos section ends ---------------------------------*/

/* -------------------------------------  Marketing tool section starts ---------------------------------*/

.marketingTool{
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    .container-mid{
        max-width: 1000px;
    }
    .marketingToolImg{
        margin-bottom: 25px;
    }
}

/* -------------------------------------  Marketing tool section ends ---------------------------------*/


/* -------------------------------------  Two Cta grid section starts ---------------------------------*/
.twoCta{
    position: relative;
    overflow: hidden;
    .container-mid{
        max-width: 1000px;
    }
    .main-heading{
        h2{
            font-size: 30px;
            @include md{
                font-size: 20px;
            }
        }
    }
    .twoCta_grid{
        background: linear-gradient(90deg, rgb(6, 30, 52) 0%, rgb(13, 111, 173) 90%);
        display: block;
        padding:20px 30px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        @include md{
            margin-top:15px;
        }
        &::before{
            content: '';
            background-image: url('../../images/pricing/bg-cta.webp');
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
            opacity: .2;
            background-size: cover;
            background-position: center;
        }
        span{
            margin-top:15px;
            display: inline-block;
            padding: 7px 10px;
            margin-bottom: 0;
        }
    }
}
/* -------------------------------------  Two Cta grid section ends ---------------------------------*/


/* -------------------------------------  Social Media Review section starts ---------------------------------*/
.socialMedia_review{
    padding: 70px 0;
    background: #091c27;
    margin-top: 70px;
    position: relative;
    overflow: hidden;
    &::before{
        content: '';
        position: absolute;
        right: 0;
        width: 231px;
        height: 100%;
        background-image: url('../../images/footer-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after{
        content:'';
        background-image: url(../../images/side-design.png);
        width:134px;
        height:251px;
        left: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;
        background-repeat: no-repeat;
    }
    .main-heading{
        max-width:1200px;
    }
}
/* -------------------------------------  Social Media Review section ends ---------------------------------*/