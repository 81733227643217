* {
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
a {
    color: var(--primary-color);
    @include transition;
    text-decoration: none;
    outline: none;
    border: none;
}

a:hover,
a:active,
a:focus {
    color: var(--secondary-color);
    text-decoration: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}
.ulStyle {
    padding-left: 15px;
    margin-bottom: 20px;
    position: relative;
    padding-left: 0 !important;
    li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 12px;
        list-style: none !important;
        &::before {
            content: '';
            position: absolute;
            background-image: url('../../images/ul-tick.webp');
            height: 25px;
            width: 25px;
            border-radius: 70%;
            left: 0;
            top: 2px;
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
button {
    border: none;
}

button:focus {
    outline: none;
    box-shadow: none;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
    @include transition;
}

.container-mid {
    position: relative;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}


/* ---------------------------- Theme Heading CSS Start --------------------------- */

.main-heading {
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    max-width:800px;
    margin:  0 auto 20px;
    h2 {
        font-size:40px;
        position: relative;
        z-index: 1;
        line-height: 1.3;
        text-transform: capitalize;
        color: var(--dark-color);
        margin-bottom: 15px;
        @include md {
            font-size: 22px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 30px;
        }
    }
    .sub-head{
        font-weight: 600;
        color: var(--text-color);
        font-size: 16px;
        text-transform: capitalize;
    }
    
    &.heading-dark {
        h2 {
            color: var(--primary-color);
        }
        p {
            color: var(--dark-color);
        }
    }
    &.heading-light {
        h2 {
            color: var(--light-color);
        }
        p {
            color: var(--light-color);
        }
        .sub-head{
            color: #e7b317;
        }
    }
}


/* ---------------------------- Theme Heading CSS End --------------------------- */


/* ------------------------------------- Page Banner CSS Start  ---------------------------------*/

.page-banner-section {
    padding-top: 150px !important;
    padding:100px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    text-align: left;
    @include md{
        padding-bottom: 50px;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, #06283D 0%, #00286552 100%);
        display: block;
        z-index: -1;
    }
    h1 {
        font-size:72px;
        color: var(--light-color);
        @include md {
            font-size: 28px;
        }
    }
    ul {
        margin-top:15px;
        li {
            color: var(--light-color);
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 800;
            &::before{
                color: var(--light-color)!important;
            }
            a {
                color: var(--light-color);
                font-size: 16px;
                @include transition;
                text-transform: capitalize;
                text-decoration: underline;
                @media (max-width: 767px) {
                    font-size: 13px;
                }
                @media (min-width: 768px) and (max-width: 991px) {
                    font-size: 14px;
                }
                @media (min-width: 992px) and (max-width: 1199px) {
                    font-size: 15px;
                }
                &.active{
                    color: var(--light-color)!important;
                    &:hover{
                        color: var(--primary-color);
                    }
                }

            }
            &.active{
                color: var(--light-color);
                &:hover{
                    color: var(--secondary-color);
                }
            }
            &:hover {
                a {
                    color: var(--secondary-color);
                    @include transition;
                }
                color: var(--secondary-color);
            }
        }
    }
}


/* ------------------------------------- Page Banner CSS End  ---------------------------------*/

.skeleton-loader {
    display: inline-block;
    width: 120px;
    height: 65px;
    background: transparent;
    background-size: 200% 100%;
    animation: loading 1.5s infinite linear;
    margin: 0 15px;
    opacity: .2;
    border-radius: 5px;
}

@keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
}